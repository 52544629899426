import { CUSTOM_ICON_NAME } from "@/components/ui/Icon";
import {
  CurrencySchema,
  employmentTypeSchema,
  locationSchema,
  zSalary,
} from "@/server/api/baseSchemas";
import { Language } from "@prisma/client";
import { z } from "zod";

export const mediaPosition = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
});

export const zTextSection = z
  .object({
    version: z.literal(1),
    kind: z.literal("text").describe("a section containing text"),
    content: z
      .string()
      .min(1)
      .describe(
        "the main content of the section. It should be engaging to read, and using Markdown to layout. Max 500 words.",
      ),
    title: z
      .string()
      .optional()
      .nullable()
      .describe(
        "a catchy hook for the section. i.e. 'We love shipping fast', 'Work with the best', 'Small and nimble', 'Industry leading'. Optional.",
      ),
    heading: z
      .string()
      .optional()
      .nullable()
      .describe(
        "a heading that describes what the content is about. Examples: 'About the job', 'About the team'. Optional.",
      ),
  })
  .describe(
    "Use this for longer, narrative content that tells a story or provides detailed information. Perfect for company introductions, job descriptions, team culture explanations, or any content that needs a cohesive flow. Best when you need 2-4 paragraphs of content. Examples: 'Role Overview', 'Our Mission'. NOTE: ABOUT THE COMPANY IS NOT A TEXT SECTION, IT IS PART OF THE COMPANY INFO SECTION.",
  );

export type TextSection = z.infer<typeof zTextSection>;

export const zTextGridSection = z
  .object({
    version: z.literal(1),
    kind: z
      .literal("text-grid")
      .describe("a section containing smaller pieces of information, can be used as a list. "),
    title: z.string().optional().nullable().describe("a catchy hook for the section, optional."),
    heading: z
      .string()
      .optional()
      .nullable()
      .describe(
        "a catchy hook for the section. i.e. 'We love shipping fast', 'Work with the best', 'Small and nimble', 'Industry leading'. Optional.",
      ),
    description: z
      .string()
      .optional()
      .nullable()
      .describe("a description for the section. Optional."),
    variant: z
      .enum(["default", "compact"])
      .optional()
      .default("default")
      .describe(
        "compact is smaller than default, but try and switch it up if too many sections are just default.",
      ),
    items: z
      .array(
        z.object({
          custom: z
            .enum(CUSTOM_ICON_NAME)
            .optional()
            .nullable()
            .describe(
              "a custom icon from the preset list of allowed custom icons that Vouch provides",
            ),
          emoji: z
            .string()
            .optional()
            .nullable()
            .describe("a emoji icon. try to always use emojis when generating with AI."),
          title: z
            .string()
            .optional()
            .nullable()
            .describe("the title of the section item, optional"),
          description: z
            .string()
            .optional()
            .nullable()
            .describe("The key content for this list item."),
        }),
      )
      .max(20)
      .optional(),
  })
  .describe(
    "Use this when information can be broken down into distinct, bite-sized points. Ideal for lists of benefits, requirements, responsibilities, or company values. Each item should be concise and focused. Perfect for scannable content like 'Key Responsibilities', 'What We Offer', 'Requirements', or 'Our Values'. Use compact variant for shorter items, default for items that need more explanation.",
  );
export type TextGridSection = z.infer<typeof zTextGridSection>;

export const zMediaImageItem = z.object({
  url: z.string(),
  crop: mediaPosition.optional(),
});

export const zImageGridSection = z
  .object({
    version: z.literal(1),
    kind: z.literal("image-grid"),
    title: z.string().optional().nullable().describe("a catchy hook for the section, optional."),
    items: z.array(zMediaImageItem).max(20).describe("an array of images."),
  })
  .describe(
    "Use this to showcase visual aspects of the workplace or team. Perfect for office photos, team events, product screenshots, or workplace culture visuals. Most effective when showing the human side of the company or the actual work environment. Avoid generic stock photos.",
  );

export type ImageGridSection = z.infer<typeof zImageGridSection>;

export const zEmbededSection = z
  .object({
    version: z.literal(1),
    kind: z.literal("embeded"),
    title: z.string().optional().nullable().describe("a catchy hook for the section, optional."),
    embededCode: z
      .string()
      .describe(
        "the embeded code. i.e. a video, audio, or other embeded content. This is the <iframe> code. Leave empty if not sure.",
      ),
  })
  .describe(
    "Use this when you want to incorporate rich media content. Ideal for company culture videos, product demos, virtual office tours, or recorded team presentations. Most effective when the content adds significant value that text alone cannot convey.",
  );

export type EmbededSection = z.infer<typeof zEmbededSection>;

export const zTimelineSection = z
  .object({
    version: z.literal(1),
    kind: z.literal("timeline"),
    title: z.string().optional().nullable().describe("a catchy hook for the section, optional."),
    description: z
      .string()
      .optional()
      .nullable()
      .describe("a description for the section. Optional."),
    items: z
      .array(
        z.object({
          title: z.string().optional().nullable().describe("the title of the event"),
          content: z.string().describe("the content of the event"),
          image: z.string().optional().nullable().describe("an image for the event, optional"),
        }),
      )
      .max(10),
  })
  .describe(
    "Use this to show progression or sequential information. Perfect for company history, career growth paths, onboarding process, or project lifecycles. Most effective when there's a clear chronological or step-by-step story to tell. Each item should represent a significant milestone or stage.",
  );
export type TimelineSection = z.infer<typeof zTimelineSection>;

export const zTestimonialSection = z
  .object({
    version: z.literal(1),
    kind: z.literal("testimonial"),
    title: z.string().optional().nullable().describe("a catchy hook for the section, optional."),
    heading: z.string().optional().nullable().describe("a heading for the section. Optional."),
    description: z
      .string()
      .optional()
      .nullable()
      .describe("a description for the section. Optional."),
    items: z
      .array(
        z.object({
          name: z.string().describe("the name of the person giving the testimonial"),
          subtitle: z.string().describe("the subtitle of the testimonial, optional"),
          testimonial: z.string().describe("the testimonial itself"),
          photo: z
            .string()
            .optional()
            .nullable()
            .describe("an image for the testimonial, optional"),
          url: z.string().optional().nullable().describe("a url for the testimonial, optional"),
        }),
      )
      .max(20),
  })
  .describe(
    "Use this to provide social proof through real employee or customer experiences. Most effective when featuring diverse perspectives from current team members, especially those in similar roles to the job posting. Testimonials should focus on growth opportunities, company culture, and day-to-day experiences. Best used to reinforce claims made in other sections with real examples.",
  );

export type TestimonialSection = z.infer<typeof zTestimonialSection>;

export const zSectionContent = z
  .union([
    zTextSection,
    zTextGridSection,
    zImageGridSection,
    zEmbededSection,
    zTimelineSection,
    zTestimonialSection,
  ])
  .and(z.object({ id: z.string().optional() }));

export type SectionContent = z.infer<typeof zSectionContent>;

export const zListingFormContent = z.object({
  companyId: z.string().min(1),
  title: z.string().min(1),
  description: z.string(),
  bounty: z.number().nonnegative(),
  currency: CurrencySchema,
  pitch: z.string().min(1),
  bannerUrl: z.string().optional().nullable(),
  highlights: z.array(z.string()).max(20),
  employmentType: z.array(employmentTypeSchema).optional().nullable(),
  must: z.string(),
  nice: z.string(),
  listingColor: z.string().optional().nullable(),
  bannerConfig: mediaPosition.optional().nullable(),

  draft: z.boolean().optional(),
  unlisted: z.boolean().optional(),
  expirationDate: z.date().nullish(),

  sections: z.array(zSectionContent).max(20),
  companySectionOverrides: z.array(zSectionContent).max(20),
  language: z.nativeEnum(Language).optional().nullable(),

  salary: zSalary.optional().nullable(),

  locations: locationSchema.array().max(20).nullable(),
});

export type ListingFormContent = z.infer<typeof zListingFormContent>;
