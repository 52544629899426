"use client";

import type { ButtonProps } from "@/components/ui/Button";
import { useEffect } from "react";
import { Button } from "@/components/ui/Button";
import { useTrackUsage } from "@/utils/metrics";
import { getCalApi } from "@calcom/embed-react";
export function CalComButton(props: ButtonProps) {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal("ui", {
        styles: {
          branding: {
            brandColor: "#000000"
          }
        },
        hideEventTypeDetails: false,
        layout: "month_view"
      });
    })().catch(console.error);
  }, []);
  const {
    trackUsage
  } = useTrackUsage();
  return <Button data-cal-namespace="" variant="yellow" rounded bordered={false} data-cal-link="sondrestaavi/utforsk-mulighetene-med-vouch" data-cal-config='{"layout":"month_view"}' {...props} onClick={e => {
    trackUsage("RequestDemo");
    props.onClick?.(e);
  }} data-sentry-element="Button" data-sentry-component="CalComButton" data-sentry-source-file="CalComButton.tsx" />;
}