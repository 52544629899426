"use client";

import { useCallback } from "react";
import { env } from "@/env.mjs";
import { useIsClient } from "@/hooks/useIsClient";
import { useSession } from "next-auth/react";
import posthog from "posthog-js";

import { fbEvent } from "./fpixel";

type Metrics =
  | "RequestDemo"
  | "Discovery"
  | "Discovery.Company"
  | "Listing.Vouch"
  | "Marketing.Listing.Generate"
  | "Marketing.Business.Generate"
  | "Vouch.Login"
  | "Vouch.Created"
  | "Vouch.StartedEditing"
  | "Vouch.Share"
  | "Vouch.Error"
  | "Vouch.Reject"
  | "Vouch.Reject.AddReason"
  | "Vouch.Accept"
  | "Vouch.Accept.ExpressInterest"
  | "Vouch.Accept.FormEdited"
  | "Vouch.Accept.Submit"
  | "Vouch.Edit.FormEdited"
  | "Vouch.Edit.Submit"
  | "Vouch.Motivation.FormEdited"
  | "Vouch.Motivation.Submit"
  | "Business.Create"
  | "Business.Create.FormEdited"
  | "Business.Create.Submit"
  | "Business.Create.Error"
  | "Business.Edit"
  | "Business.Edit.FormEdited"
  | "Business.Edit.Submit"
  | "Business.Edit.Error"
  | "Listing.Create"
  | "Listing.Create.FormEdited"
  | "Listing.Create.Save"
  | "Listing.Create.Save.AI"
  | "Listing.Create.Preview"
  | "Listing.Create.Submit"
  | "Listing.Create.Error"
  | "Listing.Edit"
  | "Listing.Edit.FormEdited"
  | "Listing.Edit.Preview"
  | "Listing.Edit.Submit"
  | "Listing.Edit.Error"
  | "Candidate.ChangeStage"
  | "Candidate.ChangeStage.Error"
  | "Candidate.ViewProfile"
  | "Candidate.Reject"
  | "Candidate.Reject.FormEdited"
  | "Candidate.Reject.Send"
  | "Candidate.Connect"
  | "Candidate.Connect.FormEdited"
  | "Candidate.Connect.Send"
  | "Candidate.RejectInProcess"
  | "Candidate.RejectInProcess.FormEdited"
  | "Candidate.RejectInProcess.Send"
  | "Candidate.StartDate"
  | "Candidate.StartDate.Send"
  | "Business.Settings.GlobalAccess.Error"
  | "Business.Settings.ListingAccess.Error"
  // generic
  | "Form.Error"
  | "Auth.Error";

export const useTrackUsage = () => {
  const session = useSession();
  const isClient = useIsClient();
  const trackUsage = useCallback(
    (metrics: Metrics, body?: Record<string, unknown>) => {
      if (env.NEXT_PUBLIC_FB_PIXEL_ID && isClient) {
        fbEvent({
          eventName: `UI-${metrics}`,
          emails: session.data?.user.email ? [session.data?.user.email] : undefined,
          firstName: session.data?.user.firstName,
          lastName: session.data?.user.lastName,
          phones: session.data?.user.phone ? [session.data?.user.phone] : undefined,
          country: session.data?.user.country,
          enableStandardPixel: true,
          ...body,
        });
      }
      if ("ko" in window) {
        (window.ko as { track: (event: string, body?: Record<string, unknown>) => void })?.track(
          `UI-${metrics}`,
          body,
        );
      }
      if (!env.NEXT_PUBLIC_POSTHOG_KEY || !env.NEXT_PUBLIC_POSTHOG_HOST) return;
      if (!posthog.__loaded) return;
      posthog.capture(`UI-${metrics}`, body);
    },
    [session, isClient],
  );
  return { trackUsage };
};

export const trackSimpleUsage = (metrics: Metrics, body?: Record<string, unknown>) => {
  if (!env.NEXT_PUBLIC_POSTHOG_KEY || !env.NEXT_PUBLIC_POSTHOG_HOST) return;
  if (!posthog.__loaded) return;
  posthog.capture(`UI-${metrics}`, body);
};
