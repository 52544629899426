import type { TCountryCode } from "countries-list";
import { Currency, Experience, Industry, Language, SubscriptionPlan } from "@prisma/client";
import { countries } from "countries-list";
import { z } from "zod";

export const CurrencySchema = z.nativeEnum(Currency);
export const IndustrySchema = z.nativeEnum(Industry);
export const ExperienceSchema = z.nativeEnum(Experience);
export const LanguageSchema = z.nativeEnum(Language);
export const SubscriptionPlanSchema = z.nativeEnum(SubscriptionPlan);

const countryNames = Object.keys(countries) as [TCountryCode, ...TCountryCode[]];
export const CountrySchema = z.enum(countryNames);

export const employmentTypeSchema = z.enum([
  "Full-time",
  "Part-time",
  "Temporary",
  "Internship",
  "Volunteer",
  "Contract",
  "Remote",
  "Hybrid",
]);

export type EmploymentType = z.infer<typeof employmentTypeSchema>;

export const zSalaryType = z.enum(["Hourly", "Monthly", "Yearly"]);

export type SalaryType = z.infer<typeof zSalaryType>;

export const zSocialMedia = z.object({
  linkedIn: z.string().nullish(),
  facebook: z.string().nullish(),
  instagram: z.string().nullish(),
  twitter: z.string().nullish(),
  tiktok: z.string().nullish(),
  youtube: z.string().nullish(),
  crunchbase: z.string().nullish(),
  glassdoor: z.string().nullish(),
});

export type SocialMedia = z.infer<typeof zSocialMedia>;

export const zSalary = z
  .object({
    minSalary: z.number().nonnegative(),
    maxSalary: z.number().nonnegative(),
    currency: CurrencySchema,
    type: zSalaryType,
    extra: z.string().optional().nullable(),
  })
  .refine((v) => {
    if (v.minSalary >= 0 && v.maxSalary >= 0) {
      return v.minSalary <= v.maxSalary && !!v.type;
    }
  });

export type Salary = z.infer<typeof zSalary>;

export const locationSchema = z.object({
  id: z.string().optional(),
  i18n: z.object({}).or(
    z
      .object({
        image: z.string().url().nullish(),
      })
      .optional(),
  ),
  address: z.string(),
  lat: z.number(),
  lng: z.number(),
  administrative_area: z.string().nullish(),
  country: z.string(),
  locality: z.string().nullish(),
  sub_administrative_area: z.string().nullish(),
  city: z.string(),
});

export type LocationSchema = z.infer<typeof locationSchema>;
