"use client";

import { Text } from "@/components/ui/Text";
import { toast } from "@/components/ui/use-toast";
import { trackSimpleUsage } from "./metrics";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultFormErrorToast = (error: Record<string, any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const iterateThroughChildren = (item: Record<string, any>) => {
    if (!item) {
      return null;
    }
    if ("message" in item) {
      return item.message as string;
    }
    return Object.entries(item).map(([key, value]: [string, {
      message: string;
    } | {
      message: string;
    }[]]) => {
      if (typeof value === "object") {
        if ("length" in value && Array.isArray(value)) {
          return <div className="ml-4" key={key}>
                {value.map((item, index) => {
              return <div key={index}>
                      {key}: {iterateThroughChildren(item)}
                    </div>;
            })}
              </div>;
        }
        if ("message" in value) {
          return <Text.P small key={key}>
                {key}: {value.message}
              </Text.P>;
        }
      }
      return <div className="ml-4" key={key}>
            {iterateThroughChildren(value)}
          </div>;
    });
  };
  console.error(error);
  trackSimpleUsage("Form.Error", {
    error
  });
  toast({
    description: iterateThroughChildren(error),
    variant: "destructive"
  });
};