import type { Prisma } from "@prisma/client";
import { mediaPosition, zSectionContent } from "@/types/SectionContent";
import { zSubscriptionCurrency, zSubscriptionPeriod } from "@/utils/stripe.utils";
import { isValidPhoneNumber } from "libphonenumber-js";
import { z } from "zod";

import {
  CountrySchema,
  IndustrySchema,
  LanguageSchema,
  locationSchema,
  SubscriptionPlanSchema,
  zSocialMedia,
} from "./baseSchemas";

const URLRegex = new RegExp(
  "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
);
const UrlRegex2 = new RegExp("^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

const literalSchema = z.union([z.string(), z.number(), z.boolean()]);
const jsonSchema: z.ZodType<Prisma.InputJsonValue> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema.nullable()), z.record(jsonSchema.nullable())]),
);

export const urlField = z.string().refine(
  (s) => {
    return z
      .string()
      .regex(URLRegex, { message: "URL" })
      .or(z.string().regex(UrlRegex2))
      .safeParse(s).success;
  },
  {
    params: {
      i18n: "invalid_url",
    },
  },
);

export const vouchFormSchema = z.object({
  // candidate info
  candidateFirstName: z.string().trim().min(1).max(50),
  candidateLastName: z.string().trim().min(1).max(50),

  // vouch info
  whyGoodFit: z.string().min(1).max(10000),
  personalMessage: z.string().max(150).optional(),
  candidateEmail: z.string().email().optional(), // make this more elegant?
  candidatePhone: z
    .string()
    .optional()
    .refine((el) => (el ? isValidPhoneNumber(el) : true), { params: { i18n: "invalid_number" } }),

  candidateLinkedIn: z.string().url().optional(),
  candidateWorkplaceAndRole: z.string().optional(),

  vouchedAnonymously: z.boolean().optional(),
  anonymousAlias: z.string().optional(),

  sendViaVouch: z.boolean().optional(),
  pledgeCharitySplit: z.boolean().optional(),
  pledgeCandidateSplit: z.boolean().optional(),

  referralSlug: z.string().optional(),
});

export const vouchFormSchemaUpdate = vouchFormSchema
  .partial()
  .refine(
    (data) => {
      if (data.vouchedAnonymously && !data.anonymousAlias?.trim()) {
        return false;
      }
      return true;
    },
    { params: { i18n: "invalid_vouch" } },
  )
  .and(z.object({ id: z.string() }));

export const vouchFormSchemaWithExtraCheck = vouchFormSchema
  .extend({
    candidatePhone: z
      .string()
      .optional()
      .transform((val) => val || undefined),
  })
  .refine(
    (data) => {
      if (data.sendViaVouch || data.vouchedAnonymously) {
        // Make sure at least one of the contact methods is filled out
        if (data.candidatePhone) {
          if (!isValidPhoneNumber(data.candidatePhone)) {
            return false;
          }
        }
        if (!data.candidatePhone?.trim() && !data.candidateEmail?.trim()) {
          return false;
        }
      }
      if (data.vouchedAnonymously && !data.anonymousAlias?.trim()) {
        return false;
      }
      return true;
    },
    { params: { i18n: "invalid_vouch" } },
  );

export const candidateUpdateVouchFormSchema = z.object({
  candidateCoverLetter: z.string().min(1).max(10000),
});

export const businessFormSchema = z.object({
  // candidate info
  orgNumber: z.string(), // look at syncing this sub schema with the frontend
  name: z.string(),
  website: urlField,
  logoUrl: z.string().url().optional(),
  bannerUrl: z.string().url().optional(),
  contactPersonName: z.string(),
  contactPersonEmail: z.string().email(),
  contactPersonPhone: z
    .string()
    .refine((el) => (!el ? true : isValidPhoneNumber(el)), { params: { i18n: "invalid_number" } })
    .optional(),
  description: z.string(),
  location: z.string().optional(),
  founded: z.number().int().min(1000).max(2030).optional(),
  employeeCount: z.number().int().optional(),
  industries: IndustrySchema.array().max(20),
  country: CountrySchema,
  language: LanguageSchema.optional(),
  businessModel: z.string().optional(),
  fundingStage: z.string().optional(),
  perksAndBenefits: z // ? what to do
    .object({
      name: z.string(),
      description: z.string().optional(),
    })
    .array()
    .max(20),
  quote: z // ? what to do
    .object({
      name: z.string(),
      position: z.string().optional(),
      quote: z.string(),
    })
    .array()
    .max(20)
    .optional(),
  keyNumbers: z // ? what to do
    .object({
      name: z.string(),
      description: z.string(),
    })
    .array()
    .max(20)
    .optional(),
  leadershipTeam: z // ? what to do
    .object({
      name: z.string(),
      photo: z.string().url().optional(),
      position: z.string(),
      description: z.string().optional(),
    })
    .array()
    .max(20)
    .optional(),
  SDGs: z.string().array().optional(), // ? what is this
});

export const companyFormSchemaV2 = z.object({
  name: z.string(),
  website: urlField,

  orgNumber: z.string().optional(),
  companyLegalName: z.string().optional(),
  logoUrl: z.string().url().optional().nullable(),
  contactPersonName: z.string().optional(),
  contactPersonEmail: z.string().email().optional(),
  contactPersonPhone: z
    .string()
    .refine((el) => (!el ? true : isValidPhoneNumber(el)), { params: { i18n: "invalid_number" } })
    .optional(),
  description: z.string().optional(),
  industries: IndustrySchema.array().max(20).optional(),
  country: CountrySchema,
  language: LanguageSchema,
  brandColor: z.string().optional(),
  sections: z.array(zSectionContent).max(20),
  bannerUrl: z.string().optional().nullable(),
  bannerConfig: mediaPosition.optional().nullable(),

  locations: z.array(locationSchema).max(20).nullable().optional(),

  socialMediaLinks: zSocialMedia.optional().nullable().optional(),

  indicatedPlanAtSignUp: SubscriptionPlanSchema.nullish(),
  indicatedCurrencyAtSignUp: zSubscriptionCurrency.nullish(),
  indicatedPeriodAtSignUp: zSubscriptionPeriod.nullish(),
});

export const createCompanySchema = z.object({
  pendingCompanyId: z.string().nullish(),
});

export const claimCandidateSchema = z.object({
  linkedInUrl: urlField,
  firstName: z.string().trim().min(1).max(50),
  lastName: z.string().trim().min(1).max(50),
  email: z.string().email(),
  phone: z
    .string()
    .refine((el) => (!el ? true : isValidPhoneNumber(el)), { params: { i18n: "invalid_number" } })
    .optional(),
});

export const userDetailSchema = z.object({
  linkedInUrl: urlField.nullable().optional(),
  firstName: z.string().trim().min(1).max(50),
  lastName: z.string().trim().min(1).max(50),
  email: z.string().trim().email().optional(),
  phone: z
    .string()
    .refine((el) => (!el ? true : isValidPhoneNumber(el)), { params: { i18n: "invalid_number" } })
    .nullable()
    .optional(),
  github: z.string().optional().nullable(),
  dribble: z.string().optional().nullable(),
  image: z.string().optional().nullable(),
  otherPortfolioLink: z.string().optional().nullable().describe("resume"),
  tagline: z.string().optional().nullable(),
  language: LanguageSchema.optional().nullable(),
  country: CountrySchema.optional().nullable(),
  nationality: CountrySchema.optional().nullable(),
  bio: z.string().optional().nullable(),
  links: jsonSchema.optional().nullable(),
  optOutNewsletter: z.boolean().optional(),
  reservedFromBeingVouchedUniversal: z.boolean().optional(),
  consentToUniversalVouchSourcing: z.date().optional().nullable(),

  candidateTags: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
    .strict()
    .array()
    .max(20)
    .optional(),
});

export const acceptVouchForm = z.object({
  user: userDetailSchema.optional(),
  primaryVouchId: z.string(),
  secondaryVouchIds: z.array(z.string()).optional(),
  rejectedVouchIds: z.array(z.string()).optional(),
  referralSlug: z.string().optional(),
});

export const superUserDetailSchema = userDetailSchema.superRefine((data, ctx) => {
  if (!(data.linkedInUrl || data.otherPortfolioLink)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["linkedInUrl"],
      params: { i18n: "invalid_linkedin_or_resume" },
    });
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["otherPortfolioLink"],
      params: { i18n: "invalid_linkedin_or_resume" },
    });
  }
});

export const businessInterestForm = z
  .object({
    name: z.string().min(1).max(50),
    email: z.string().email(),
    phone: z
      .string()
      .refine((el) => (!el ? true : isValidPhoneNumber(el)), { params: { i18n: "invalid_number" } })
      .optional(),
  })
  .strict();

const userOrSlug = z.union([
  z.object({
    userId: z.string(),
  }),
  z.object({
    slug: z.string(),
  }),
]);

export const createTrackingInput = z.union([
  z.object({
    type: z.literal("VOUCH_SHARING"),
    vouchId: z.string(),
    listingId: z.string(),
  }),
  z.object({
    type: z.literal("LISTING_SHARING"),
    listingId: z.string(),
    companyId: z.string(),
  }),
  z.object({
    type: z.literal("COMPANY_SHARING"),
    companyId: z.string(),
  }),
  z.object({
    type: z.literal("REQUEST_VOUCH"),
    listingId: z.string(),
  }),
]);

export const trackingInputSchema = createTrackingInput.and(userOrSlug);

export const updateCompanyBillingSchema = z.object({
  companyId: z.string(),
  taxId: z.string().optional(),
  invoiceEmail: z.string().email().nullish(),
  invoiceComment: z.string().nullish(), // PO number?
  address: z.object({
    companyLegalName: z.string(),
    line1: z.string(),
    line2: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.string().length(2),
  }),
});

export const userProfileSchema = z.object({
  careerHighlights: z.string().nullish(),
  futureInterests: z.string().nullish(),
  experienceSummary: z.string().nullish(),
  keyStrengths: z.string().nullish(),
});
